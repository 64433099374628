const config = {
  contentful: {
    spaceId: process.env.REACT_APP_CONTENTFUL_SPACE_ID || '',
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
  }
};

// This function will be used to check if the configuration is valid
export const isConfigValid = () => {
  return config.contentful.spaceId && config.contentful.accessToken;
};

export default config; 
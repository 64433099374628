import { createClient } from 'contentful';
import { marked } from 'marked';
import config, { isConfigValid } from '../config';

// Configure marked options for better security and features
marked.setOptions({
  gfm: true, // GitHub Flavored Markdown
  breaks: true, // Convert \n to <br>
  headerIds: true, // Add IDs to headers
  mangle: false, // Don't escape HTML
  sanitize: false, // Don't sanitize HTML (we trust Contentful's content)
});

let client = null;

const getClient = () => {
  if (!isConfigValid()) {
    console.error('Contentful credentials are not configured');
    return null;
  }

  if (!client) {
    client = createClient({
      space: config.contentful.spaceId,
      accessToken: config.contentful.accessToken,
    });
  }

  return client;
};

export const getBlogPosts = async () => {
  try {
    const client = getClient();
    if (!client) {
      return [];
    }

    const entries = await client.getEntries({
      content_type: 'blogPost',
      order: '-sys.createdAt',
    });

    const sanitizedEntries = entries.items.map((item) => ({
      id: item.sys.id,
      slug: item.fields.slug,
      title: item.fields.title,
      excerpt: item.fields.excerpt,
      content: marked(item.fields.content), // Parse Markdown to HTML
      date: new Date(item.sys.createdAt).toLocaleDateString(),
      author: item.fields.author || 'SwiftForms Team',
      metaDescription: item.fields.metaDescription,
    }));

    return sanitizedEntries;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    return [];
  }
};

export const getBlogPostBySlug = async (slug) => {
  try {
    const client = getClient();
    if (!client) {
      return null;
    }

    const entries = await client.getEntries({
      content_type: 'blogPost',
      'fields.slug': slug,
      limit: 1,
    });

    if (entries.items.length === 0) {
      return null;
    }

    const item = entries.items[0];
    return {
      id: item.sys.id,
      slug: item.fields.slug,
      title: item.fields.title,
      content: marked(item.fields.content), // Parse Markdown to HTML
      date: new Date(item.sys.createdAt).toLocaleDateString(),
      author: item.fields.author || 'SwiftForms Team',
      metaDescription: item.fields.metaDescription,
    };
  } catch (error) {
    console.error('Error fetching blog post:', error);
    return null;
  }
}; 